import type { NuxtAxiosInstance } from '@nuxtjs/axios'
import type { Context } from '@nuxt/types'
import { Integration, IntegrationsUpdateResponse, IntegrationsDeleteResponse, LeverAuthURL, SetupInfo } from '@/services/types/Integrations'

interface IIntegrationService {
  $axios: NuxtAxiosInstance
  getIntegrations: (tenantId: string) => Promise<Array<Integration>>
  update: (tenantId: string, integration: string) => Promise<IntegrationsUpdateResponse>
  delete: (tenantId: string, integration: string) => Promise<IntegrationsDeleteResponse>
  getLeverAuthURL: (tenantId: string) => Promise<LeverAuthURL>
  getWebhookSetupInfo: (provider: string, tenantId: string) => Promise<SetupInfo>
}

export default class IntegrationService implements IIntegrationService {
  $axios: NuxtAxiosInstance

  constructor (context: Context) {
    this.$axios = context.$axios
  }

  async getIntegrations (tenantId: string): Promise<Array<Integration>> {
    return await this.$axios.$get(`/api/integrations/${tenantId}/list`)
  }

  async update (tenantId: string, integration: string): Promise<IntegrationsUpdateResponse> {
    return await this.$axios.$post(`/api/integrations/${tenantId}/update`, integration)
  }

  async delete (tenantId: string, integration: string): Promise<IntegrationsDeleteResponse> {
    return await this.$axios.$post(`/api/integrations/${tenantId}/delete`, integration)
  }

  async getLeverAuthURL (tenantId: string): Promise<LeverAuthURL> {
    return await this.$axios.$get(`/api/integrations/${tenantId}/lever-oauth-url`)
  }

  async getWebhookSetupInfo (provider: string, tenantId: string): Promise<SetupInfo> {
    return await this.$axios.$get(`/api/integrations/webhooks/${provider}/${tenantId}/setup-info`)
  }
}
